@import './swatches';
$body-breakpoint: 800px;

body {
  font-family: 'Roboto', serif;
  font-weight: 300;
  color: $swatch01;
  max-width: $body-breakpoint;
  $body-padding: 10px;
  $body-padding-breakpoint: $body-breakpoint + ($body-padding * 4);
  padding: 0 $body-padding;

  @media all and (min-width: $body-padding-breakpoint) {
    margin: 0 auto;
    margin-bottom: 20px;
    padding: 0;
  }
}

a {
  text-decoration: none;
  cursor: pointer;

  &:link,
  &:visited {
    color: $swatch02;
  }

  &:hover,
  &:active {
    color: $swatch01;
  }
}

header {
  margin-top: 20px;

  .me {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .name {
    font-size: 36px;
  }

  .links {
    margin-top: 4px;
    text-align: right;
  }

  .link {
    border-right: 1px solid $swatch04;
    padding: 0 8px 0 4px;

    &:last-child {
      border-right: 0;
      padding-right: 0;
    }
  }
}

main {
  margin: 0;

  h2 {
    font-family: 'Roboto', sans-serif;
    font-size: 32px;
    font-weight: 300;
    padding-bottom: 4px;
    border-bottom: 1px solid $swatch03;
    text-align: left;
    margin: 0;
    margin-top: 40px;
    margin-bottom: 20px;

    &:first-child {
      margin-top: 10px;

      @media all and (min-width: 400px) {
        margin-top: 0;
      }
    }
  }

  p {
    font-size: 16px;
    line-height: 22px;
    word-spacing: 1px;
  }

  .company {
    display: flex;
    flex-direction: column;
    margin-top: 40px;

    &:first-child {
      margin-top: 0;
    }

    @media all and (min-width: 700px) {
      flex-direction: row;
    }
  }

  .image-wrap {
    width: 175px;
    min-width: 175px;
    height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 20px;
    padding-bottom: 20px;

    @media print {
      display: none;
    }
  }

  .inner-company {
    display: flex;
    margin-top: 20px;

    .image-wrap {
      width: 75px;
      min-width: 75px;
      height: 30px;
      padding: 0;
    }
  }

  .image {
    max-width: 100%;
    max-height: 100%;
  }

  .title {
    font-size: 26px;
  }

  .company-name {
    margin-top: 6px;
    font-size: 20px;
  }

  .details {
    margin-top: 4px;
    font-size: 16px;
    color: $swatch04;
  }

  .duration {
    padding-right: 12px;
  }

  .location {
    padding-left: 12px;
  }

  .awards {
    font-size: 16px;
    line-height: 20px;
    padding-left: 18px;

    @media all and (min-width: $body-breakpoint) {
      padding-left: 40px;
    }

    li {
      padding: 4px 0;
    }
  }

  .education {
    display: flex;
    align-items: center;
    padding-bottom: 10px;

    .image-wrap {
      width: 45px;
      min-width: 45px;
      height: 45px;
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 0;
    }

    .description {
      p {
        margin: 0;
      }
    }
  }
}
